jQuery(function ($) {
  $(".second_prod").on("click", function (e) {
    e.preventDefault();
    var pid = $(this).attr("value");
    var prod = $(this);
    $(".options").addClass("loading");
    if (!$(prod).hasClass("added")) {
      $.ajax({
        url: main_ajax.ajax_url,
        type: "post",
        data: {
          action: "add_product_to_cart",
          security: main_ajax.check_nonce,
          pid: pid,
        },
        success: function (response) {
          $(document.body).trigger("wc_fragment_refresh");
          $(prod).addClass("added");
          $(".options").removeClass("loading");
        },
        fail: function () {
          console.log("error");
        },
      });
    } else {
      $.ajax({
        url: main_ajax.ajax_url,
        type: "post",
        data: {
          action: "remove_product_from_cart",
          security: main_ajax.check_nonce,
          pid: pid,
        },
        success: function (response) {
          $(document.body).trigger("wc_fragment_refresh");
          $(prod).removeClass("added");
          $(".options").removeClass("loading");
        },
        fail: function () {
          console.log("error");
        },
      });
    }
  });
})
