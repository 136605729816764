import Swiper from "swiper/bundle";

jQuery(function ($) {
  const hero = new Swiper(".hero", {
    loop: true,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
    },
    autoplay: {
      delay: 5000,
    },
    mousewheel: { forceToAxis: true },
    breakpoints: {
      1700: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
  });

  const cardLins = new Swiper(".card-links", {
    slidesPerView: 1,
    spaceBetween: 20,
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
    },
    breakpoints: {
      1700: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
  });
});
