import flatpickr from "flatpickr";

jQuery(function ($) {
  $(document).on("click", ".plus", function (e) {
    e.preventDefault();
    var val = $(this).parents(".quantity").find("input.qty").val();
    if (val) {
      if (parseFloat(val) >= 1) {
        var newVal = parseFloat(val) + 1;
        $(this)
          .parents(".quantity")
          .find("input.qty")
          .val(newVal)
          .trigger("change");
      }
    } else {
      var oldVal = 0;
      if (parseFloat(oldVal) >= 0) {
        var newVal = parseFloat(oldVal) + 1;
        $(this)
          .parents(".quantity")
          .find("input.qty")
          .val(newVal)
          .trigger("change");
      }
    }
  });
  $(document).on("click", ".minus", function (e) {
    e.preventDefault();
    var val = $(this).parents(".quantity").find("input.qty").val();
    if (val) {
      if ($("body").hasClass("woocommerce-checkout")) {
        if (parseFloat(val) > 0) {
          var newVal = parseFloat(val) - 1;
          $(this)
            .parents(".quantity")
            .find("input.qty")
            .val(newVal)
            .trigger("change");
        }
      } else {
        if (parseFloat(val) > 1) {
          var newVal = parseFloat(val) - 1;
          $(this)
            .parents(".quantity")
            .find("input.qty")
            .val(newVal)
            .trigger("change");
        }
      }
    }
  });

  $("input[name=company-selector]").on("change", function () {
    var category = this.value;
    if (category == "company") {
      $("#smartbill_billing_cif_field").css("display", "block");
      $("#smartbill_billing_company_name_field").css("display", "block");
      $("#smartbill_billing_nr_reg_com_field").css("display", "block");
    } else {
      $("#smartbill_billing_cif_field").css("display", "none");
      $("#smartbill_billing_company_name_field").css("display", "none");
      $("#smartbill_billing_nr_reg_com_field").css("display", "none");
    }
  });

  $(".woocommerce-form-coupon").appendTo($("#coupon-anchor"));

  $("input#order_specific_hour").flatpickr({
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
    disableMobile: "true",
    onValueUpdate: function (obj, updatedTime) {
      if (updatedTime > "00:00" && updatedTime < "10:00") {
        $("input#order_specific_hour").val("10:00");
        $(".flatpickr-hour").val("10");
      }
    },
  });

  $("body").on("update_checkout", function () {
    if ($("#order_hour_selector").is(":checked")) {
      $("#order_review").addClass("hide-free");
    } else {
      $("#order_review").removeClass("hide-free");
    }
  });

  $("input[name=client-selector]").on("change", function () {
    var client = this.value;
    if (client == "login") {
      $(".woocommerce-form-login").slideDown();
      $("div.create-account").hide();
      $(".wp-social-login-widget").show();
    } else if (client == "register") {
      $(".woocommerce-form-login").slideUp();
      $("div.create-account").show();
      $("input#createaccount").prop("checked", true);
      $(".wp-social-login-widget").show();
    } else {
      $("div.create-account").hide();
      $(".woocommerce-form-login").slideUp();
      $("input#createaccount").prop("checked", false);
      $(".wp-social-login-widget").hide();
    }
  });

  $("form.checkout").on("change", ".qty", function (e) {
    var data = {
      action: "update_order_review",
      security: wc_checkout_params.update_order_review_nonce,
      post_data: $("form.checkout").serialize(),
    };

    jQuery.post(main_ajax.ajax_url, data, function (response) {
      $("body").trigger("update_checkout");
    });
  });

  $("#ship-to-different-address-checkbox").on("change", function () {
    $("#shipping_method_0_free_shipping3").trigger("click");

    if ($("#ship-to-different-address-checkbox").is(":checked")) {
      $("#purple-pickup").show();
    } else {
      if (!$("#purple-pickup-input").is(":checked")) {
        $("#purple-pickup").hide();
      }
    }
  });

  // DEFAULT to FREE DELIVERY CHECKOUT
  $("#shipping_method_0_free_shipping3").trigger("click");

  $("#purple-pickup-input").on("change", function () {
    $("#ship-to-different-address-checkbox").trigger("click");

    if ($("#purple-pickup-input").is(":checked")) {
      $("#shipping_method_0_local_pickup4").trigger("click");
      $("#ship-to-different-address").hide();
    } else {
      $("#shipping_method_0_free_shipping3").trigger("click");
      $("#ship-to-different-address").show();
    }
  });

  if ($(window).width() < 700) {
    $(".radio-input input:checked").parents(".radio-input").addClass("active");

    $(".radio-input input").on("change", function () {
      $(this)
        .parents(".client-selector, .company-selector")
        .find(".radio-input")
        .removeClass("active");

      $(this).parents(".radio-input").addClass("active");
    });
  }
});
