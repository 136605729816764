import "./_add_sliders";
import "./_add_extras";
import "./_add_checkout";

import "../../node_modules/masonry-layout/dist/masonry.pkgd";
import GLightbox from "glightbox";
import Sharer from "sharer.js";
import Cookies from "js-cookie";

jQuery(function ($) {
  const lightbox = GLightbox();

  $(".pachet__selector a").on("click", function (e) {
    let target = $(this).attr("href");
    e.preventDefault();
    $(this).parents("li").toggleClass("active");
    $(".pachet__content").hide();
    $(target).show();
  });

  $(".product-info__title").on("click", function () {
    $(this)
      .toggleClass("is-open")
      .parents(".product-info__row")
      .find(".product-info__text")
      .slideToggle();
  });

  $("input[name=company-selector]").on("change", function () {
    var category = this.value;
    if (category == "company") {
      $(".wbfr_company_details,#billing_company_field").show();
      $("#smartbill_billing_type").val("pj").trigger("change");
    } else {
      $(".wbfr_company_details,#billing_company_field").hide();
      $("#smartbill_billing_type").val("pf").trigger("change");
    }
  });

  if ($(".masonry").length) {
    $(".masonry").masonry({
      // options...
      itemSelector: "a",
    });
  }

  //PROPS

  function updateProduse() {
    var products = [];
    $(".props-form .items li").each(function () {
      var product = $(this).find(".name").text();
      products.push(product);
    });
    $(".produse textarea").val(products);
  }

  $(document).on("click", ".prop-prod", function () {
    var id = $(this).attr("id");
    var produs = $(this).find(".title").text();

    if ($(this).hasClass("selected")) {
      $(this).removeClass("selected");
      $('.props-form li[data-prod="' + id + '"]').remove();
    } else {
      $(this).addClass("selected");
      $(".props-form ul").append(
        '<li data-prod="' +
          id +
          '"><span class="close">×</span><span class="name">' +
          produs +
          "</span></li>"
      );
    }
    updateProduse();
  });

  $(document).on("click", ".props-form .close", function () {
    var id = $(this).closest("li").attr("data-prod");
    $('.props-form li[data-prod="' + id + '"]').remove();
    $("#" + id).removeClass("selected");
    updateProduse();
  });

  $(".register-toggle").on("click", function (e) {
    e.preventDefault();
    $("#customer_login").toggleClass("toggle-the-logins");
  });

  $(".toggle-btn").on("click", function () {
    $(this).toggleClass("menu-collapsed");
    $(this).next("ul.sub-menu").slideToggle();
  });

  $(".burger, .menusearch").on("click", function () {
    $("body").toggleClass("show-burger");
  });

  //ESCAPE
  document.onkeydown = function (evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) {
      isEscape = evt.key === "Escape" || evt.key === "Esc";
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape) {
      $("body").removeClass("show-burger");
    }
  };

  //CLICK OUTSIDE

  $("body").on("click", function (e) {
    if (e.target !== this) return;
    $("body").removeClass("show-burger");
  });

  $('a[href="#programare"], .pop-programare .close').on("click", function (e) {
    e.preventDefault();
    $(".pop-programare").fadeToggle();
  });

  $(".newsletter-popup .close").on("click", function () {
    $(".newsletter-popup").fadeOut();
    Cookies.set("newsletter-cookie", "yes", { expires: 30 });
  });

  if (Cookies.get("newsletter-cookie") != "yes") {
    setTimeout(function () {
      $(".newsletter-popup").fadeIn();
    }, 10000);
  }

  // Function to load orders with optional date filters
  function loadOrders(filterDate, startDate, endDate) {
    $.ajax({
      url: main_ajax.ajax_url,
      type: "POST",
      data: {
        action: "get_orders",
        filter_date: filterDate,
        start_date: startDate,
        end_date: endDate,
        nonce: main_ajax.check_nonce,
      },
      success: function (response) {
        $("#orders-container").html(response);
        $("#orders-container").removeClass("loading");
      },
    });
  }

  // Function to toggle order status
  function toggleOrderStatus(orderId, button) {
    $.ajax({
      url: main_ajax.ajax_url,
      type: "POST",
      data: {
        action: "toggle_order_status",
        order_id: orderId,
        nonce: main_ajax.check_nonce,
      },
      success: function (response) {
        if (response.success) {
          // Change the button label based on the current status
          loadOrders(
            $("#filter-date").val(),
            $("#start-date").val(),
            $("#end-date").val()
          );
        } else {
          alert("Failed to update order status.");
        }
      },
    });
  }

  // Function to add a new message
  function addOrderMessage(orderId, message) {
    $.ajax({
      url: main_ajax.ajax_url,
      type: "POST",
      data: {
        action: "add_order_message",
        order_id: orderId,
        message: message,
        nonce: main_ajax.check_nonce,
      },
      success: function (response) {
        if (response.success) {
          loadOrders(
            $("#filter-date").val(),
            $("#start-date").val(),
            $("#end-date").val()
          );
        } else {
          alert("Failed to add message.");
        }
      },
    });
  }

  // Load orders on page load
  loadOrders("all");

  // Show/hide date range fields based on filter selection
  $("#filter-date").change(function () {
    if ($(this).val() === "date-range") {
      $("#date-range-fields").show();
    } else {
      $("#date-range-fields").hide();
    }
  });

  // Apply filters button click event
  $("#apply-filters").on("click", function () {
    var filterDate = $("#filter-date").val();
    var startDate = $("#start-date").val();
    var endDate = $("#end-date").val();
    $("#orders-container").addClass("loading");
    loadOrders(filterDate, startDate, endDate);
  });

  // Event delegation for dynamic content
  $(document).on("click", ".btn-status", function () {
    var orderId = $(this).data("order-id");
    $("#orders-container").addClass("loading");
    toggleOrderStatus(orderId, this);
  });

  // Event delegation for adding a new message
  $(document).on("submit", ".add-message-form", function (e) {
    e.preventDefault();
    var orderId = $(this).data("order-id");
    var message = $(this).find('input[name="message"]').val();
    addOrderMessage(orderId, message);
  });

  $("#start-date, #end-date").flatpickr({
    enableTime: false,
    time_24hr: true,
    disableMobile: "true",
    dateFormat: "Y-m-d",
  });
});
